import React from "react";
import { Select as AntdSelect, SelectProps } from 'antd';

export const Select: React.FC<SelectProps> = ({ ...rest }) => {
    return(
        <AntdSelect
            size="large"
            className="md:w-[230px]"
            {...rest}
        />
    );
}