// storageService.ts

import { apiForm, apiRequest } from "./api";
import { message } from "antd";

export const postAllTypeFile = async (
  file: File,
  reportUuid: string
): Promise<ReadStorageDto | undefined> => {
  try {
    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("reportUuid", reportUuid);

    const response = await apiForm.post(`/storage/upload-file`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (response && response.data) {
      return response.data;
    }
  } catch (error: any) {
    console.error("Error during file upload:", error);

    if (error.response) {
      message.error(error.response.data.message ?? "Falha na requisição");
    } else {
      message.error("Falha na requisição");
    }

    return undefined;
  }
};

export const postAllTypeFileTask = async (
  file: File,
  taskUuid: string
): Promise<ReadStorageDto | undefined> => {
  try {
    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("taskUuid", taskUuid);

    const response = await apiForm.post(
      `/storage/upload-file-to-task`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response && response.data) {
      return response.data;
    }
  } catch (error: any) {
    console.error("Error during file upload:", error);

    if (error.response) {
      message.error(error.response.data.message ?? "Falha na requisição");
    } else {
      message.error("Falha na requisição");
    }

    return undefined;
  }
};

export const postAllTypeFileClosedReportIssue = async (
  file: File,
  closedReportIssueUuid: string
): Promise<ReadStorageDto | undefined> => {
  try {
    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("closedReportIssueUuid", closedReportIssueUuid);

    const response = await apiForm.post(
      `/storage/upload-file-to-closedReportIssue`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response && response.data) {
      return response.data;
    }
  } catch (error: any) {
    console.error("Error during file upload:", error);

    if (error.response) {
      message.error(error.response.data.message ?? "Falha na requisição");
    } else {
      message.error("Falha na requisição");
    }

    return undefined;
  }
};

export async function getStorageByUuid(uuid: string) {
  const response = await apiRequest<any>(
    "get",
    `/storage/find-by-uuid?uuid=${uuid}`
  );
  return response;
}

export async function getStorageByReportUuid(reportUuid: string) {
  const response = await apiRequest<any>(
    "get",
    `/storage/find-by-reportUuid?reportUuid=${reportUuid}`
  );
  return response;
}

export async function getDownloadFile(uuid: string) {
  const response = await apiRequest<any>(
    "get",
    `/storage/download?fileUuid=${uuid}`
  );
  return response;
}

export async function getStorageByTaskUuid(taskUuid: string) {
  const response = await apiRequest<any>(
    "get",
    `/storage/find-by-taskUuid?taskUuid=${taskUuid}`
  );
  return response;
}

export async function getStorageByClosedReportIssueUuid(
  closedReportIssueUuid: string
) {
  const response = await apiRequest<any>(
    "get",
    `/storage/find-by-closedReportIssueUuid?closedReportIssueUuid=${closedReportIssueUuid}`
  );
  return response;
}

export const postFileProfile = async (
  file: File,
  userUuid: string
): Promise<ReadStorageDto | undefined> => {
  try {
    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("userUuid", userUuid);

    const response = await apiForm.post(`/storage/profile-image`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (response && response.data) {
      return response.data;
    }
  } catch (error: any) {
    console.error("Error during file upload:", error);

    if (error.response) {
      message.error(error.response.data.message ?? "Falha na requisição");
    } else {
      message.error("Falha na requisição");
    }

    return undefined;
  }
};
