import React, { useState, useEffect, useContext } from "react";
import {
  Avatar,
  Button,
  Dropdown,
  Input,
  Menu,
  MenuProps,
  Tooltip,
} from "antd";
import { Img } from "components/Img";
import { useNavigate } from "react-router-dom";
import { AuthContext, signOutData } from "contexts/AuthContext";
import CollapsibleMenu from "components/MenuCollapsible";
import { getUsersByUuid } from "services/userService";
import { UnorderedListOutlined } from "@ant-design/icons";

import { AuthAdminProvider } from "contexts/AuthContextAdmin";

interface UserData {
  uuid: string;
  name: string;
  email: string;
  type: string;
  profileImageUrl: string;
}

const LayoutBaseAdmin = ({ children }: { children: React.ReactNode }) => {
  const { user } = useContext(AuthContext);

  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState<UserData | null>(null);
  const [currentPage, setCurrentPage] = useState<string>(
    window.location.pathname
  );
  const [collapsed, setCollapsed] = useState(false);

  const handleToggleCollapse = () => {
    setCollapsed((prevCollapsed) => !prevCollapsed);
  };

  const handleLogout = () => {
    signOutData();
    navigate("/");
    window.location.reload();
  };

  const navigateTo = (path) => {
    setCurrentPage(path);
    navigate(path);
  };

  const getUsersData = async () => {
    try {
      if (user && user.uuid) {
        const response = await getUsersByUuid(user.uuid);

        setUserInfo(response);
      }
    } catch (error) {
      console.error("Error fetching users data:", error);
    }
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <a onClick={() => navigate(`/admin/profile`)}>Profile</a>,
    },
    {
      key: "2",
      label: (
        <a rel="noopener noreferrer" onClick={handleLogout}>
          Logout
        </a>
      ),
    },
  ];

  const itemsMenu: MenuProps["items"] = [
    {
      key: "1",
      label: <a onClick={() => navigate(`/admin/dashboard`)}>Dashboard</a>,
    },
    {
      key: "2",
      label: <a onClick={() => navigate(`/admin/reports`)}>Reports</a>,
    },
    {
      key: "3",
      label: (
        <a onClick={() => navigate(`/admin/integrations`)}>Integrations</a>
      ),
    },
  ];

  useEffect(() => {
    getUsersData();
  }, [user]);

  return (
    <AuthAdminProvider>
      <div className="w-screen max-w-full flex flex-col min-h-screen font-nunito min-w-full">
        <div className="py-5 px-5 flex lg:flex-row flex-col justify-between lg:items-center gap-5 border-b border-[##EAEEF4]">
          <div className="flex gap-[60px] items-center">
            <Img
              src="/images/logotipo.svg"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/admin/dashboard")}
            />
          </div>
          <div>
            {/* Menu Mobile */}
            <div className="block lg:hidden">
              <div className="flex justify-between items-center gap-2">
                <Dropdown menu={{ items: itemsMenu }} placement="bottom" arrow>
                  <Button shape="circle" icon={<UnorderedListOutlined />} />
                </Dropdown>
                <div
                  style={{ cursor: "pointer" }}
                  className="flex items-center gap-2"
                >
                  <Dropdown menu={{ items }} placement="bottom" arrow>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Avatar size={50} src={userInfo?.profileImageUrl} />
                      <p style={{ margin: "0 10px" }}>{user?.name}</p>
                    </div>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden lg:block">
            <div className="flex items-center gap-2">
              <Button
                shape="circle"
                icon={<Img src="/images/notification-bing.png" />}
              />
              <div
                style={{ cursor: "pointer" }}
                className="flex items-center gap-2"
              >
                <Dropdown menu={{ items }} placement="bottom" arrow>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Avatar size={50} src={userInfo?.profileImageUrl} />
                    <p style={{ margin: "0 10px" }}>{user?.name}</p>
                  </div>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>

        <div className="flex" style={{ width: "100%" }}>
          <div>
            <CollapsibleMenu
              collapsed={collapsed}
              className="lg:w-auto hidden lg:block"
              items={[
                {
                  key: "1",
                  icon:
                    currentPage === "/admin/dashboard" ? (
                      <Img
                        src="/images/dashboard-white.svg"
                        className="w-5 h-5 flex items-center justify-center"
                      />
                    ) : (
                      <Img
                        src="/images/dashboard.svg"
                        className="w-5 h-5 flex items-center justify-center"
                      />
                    ),
                  buttonClassName: `${
                    currentPage === "/admin/dashboard"
                      ? "bg-[#3B82F6]"
                      : "bg-white-A700"
                  }`,
                  title: "Dashboard",
                  path: "/admin/dashboard",
                },
                {
                  key: "2",
                  icon:
                    currentPage === "/admin/reports" ? (
                      <Img
                        src="/images/security-white.svg"
                        className="w-5 h-5 flex items-center justify-center"
                      />
                    ) : (
                      <Img
                        src="/images/security.svg"
                        className="w-5 h-5 flex items-center justify-center"
                      />
                    ),
                  buttonClassName: `${
                    currentPage === "/admin/reports"
                      ? "bg-[#3B82F6]"
                      : "bg-white-A700"
                  }`,
                  title: "Reports",
                  path: "/admin/reports",
                },
                {
                  key: "3",
                  icon:
                    currentPage === "/admin/integrations" ? (
                      <Img
                        src="/images/integrations-white.svg"
                        className="w-5 h-5 flex items-center justify-center"
                      />
                    ) : (
                      <Img
                        src="/images/integrations.svg"
                        className="w-5 h-5 flex items-center justify-center"
                      />
                    ),
                  buttonClassName: `${
                    currentPage === "/admin/integrations"
                      ? "bg-[#3B82F6]"
                      : "bg-white-A700"
                  }`,
                  title: "Integrations",
                  path: "/admin/integrations",
                },
              ]}
              handleToggleCollapse={handleToggleCollapse}
              navigate={navigateTo}
            />
          </div>

          <div className="lg:p-10 lg:w-[90%] w-[100%]">{children}</div>
        </div>
      </div>
    </AuthAdminProvider>
  );
};

export default LayoutBaseAdmin;
