import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import 'dayjs/locale/en';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

type DateString = string;

export const formatDate = (dateString: DateString): string => {
  return dayjs(dateString).locale('en').format('MM/DD/YYYY');
};

export const formatTime = (dateString: DateString): string => {
  return dayjs(dateString).locale('en').format('HH:mm');
};

export const formatFormalDate = (dateString: DateString): string => {
  return dayjs(dateString).locale('en').format('MMMM DD, YYYY - HH:mm');
};

export const formatRelativeDate = (dateString: DateString): string => {
  return dayjs(dateString).locale('en').fromNow();
};
