import {
  api,
  apiRequest,
  apiRequestWhistleBlower,
  apiWhistleBlower,
} from "./api";

export async function createReport(reportData: CreateReportDto) {
  const response = await apiRequest<any>("post", "/reports/create", reportData);
  return response;
}

export async function generateReport(uuid: string) {
  const response = await apiRequest<any>(
    "get",
    `/reports/generate?uuid=${uuid}`
  );
  return response;
}

export async function loginReport(code: string) {
  return apiRequestWhistleBlower<ReadReportAuthDto>(
    "post",
    `/reports/generate-report-token`,
    {
      reportCode: code,
    }
  );
}

export async function verifyReportToken(): Promise<
  ReportSimplifiedtDto | undefined
> {
  try {
    return apiRequestWhistleBlower<ReportSimplifiedtDto>(
      "get",
      `/reports/verify-report-token`
    );
  } catch (error: any) {
    if (error.response) {
      console.error(error.response.data.message);
    } else {
      console.error("Erro desconhecido:", error);
    }
  }
}

export async function getReportByUuid(uuid: string) {
  const response = await apiRequest<any>(
    "get",
    `/reports/find-by-uuid?uuid=${uuid}`
  );
  return response;
}

export async function getReportByCode(code: string) {
  const response = await apiRequest<any>(
    "get",
    `/reports/find-by-code?code=${code}`
  );
  return response;
}

export async function getReportByCodeAdmin(code: string) {
  const response = await apiRequest<any>(
    "get",
    `/reports/find-by-code-admin?code=${code}`
  );
  return response;
}

export async function getReportsListAndSearch(
  page: number,
  itemsPerPage: number,
  search: string
) {
  const response = await apiRequest<any>(
    "get",
    `/reports/list?page=${page}&itemsPerPage=${itemsPerPage}&search=${search}`
  );
  return response;
}

export async function updateReport(uuid: string, reportData: UpdateReportDto) {
  const response = await apiRequest<any>(
    "put",
    `/reports/update?uuid=${uuid}`,
    reportData
  );
  return response;
}

export async function updateReportStatus(
  reportUuid: string,
  reportStatusUUID: string
) {
  const response = await apiRequest<any>(
    "put",
    `/reports/update-reportStatus?uuid=${reportUuid}`,
    { reportStatusUUID }
  );
  return response;
}

export async function updateReportIssuePriority(
  reportUuid: string,
  reportIssuePriorityUUID: string
) {
  const response = await apiRequest<any>(
    "put",
    `/reports/update-reportIssuePriority?uuid=${reportUuid}`,
    { reportIssuePriorityUUID }
  );
  return response;
}

export async function generateReportIssuesByReportUuid(reportUuid: string) {
  const response = await apiRequest<any>(
    "get",
    `/reports/generate?uuid=${reportUuid}`
  );
  return response;
}
