import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";

import { Img, Text } from "components";
import {
  Avatar,
  Button,
  Form,
  Input,
  Spin,
  notification,
  Pagination,
} from "antd";
import TitleWithBreadCrumb from "components/TitleWithBreadCrumb";
import queryString from "query-string";
import { getReportByCodeAdmin } from "services/reportService";
import {
  createInbox,
  getInboxByUuid,
  getInboxListByReportUuid,
  updateInbox,
} from "services/inboxService";
import { PlusOutlined } from "@ant-design/icons";
import draftToHtml from "draftjs-to-html";
import { EditorState, convertToRaw } from "draft-js";
import { formatDate, formatFormalDate, formatTime } from "utils/formatDate";
import LayoutBaseAdmin from "components/LayoutBaseAdmin";
import dayjs from "dayjs";
import ModalText from "components/ModalText";

type InboxItem = {
  uuid: string;
  title: string;
  body: string;
  topic?: string;
  name?: string;
  emails: string[];
  emailCc?: string[];
  viewed?: boolean;
  admin_sent?: boolean;
  createdAt: string;
};

type InboxDetail = {
  title: string;
  body: string;
  topic?: string;
  name?: string;
  emails: string[];
  emailCc?: string[];
  viewed?: boolean;
  admin_sent?: boolean;
  createdAt: string;
};

type InboxListResponse = {
  data: InboxItem[];
  total: number;
};

const InboxAdmin = () => {
  const [toEmails, setToEmails] = useState<string[]>([]);
  const [ccEmails, setCcEmails] = useState<string[]>([]);
  const [focused, setFocused] = React.useState(false);
  const { code } = queryString.parse(window.location.search);
  const [form] = Form.useForm();

  const [isWriteEmail, setIsWriteEmail] = useState<boolean>(false);
  const [isReply, setIsReply] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [componentMounted, setComponentMounted] = useState<boolean>(false);
  const [firstMessage, setFirstMessage] = useState<boolean>(false);

  const [actualPage, setActualPage] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [totalPerPage, setTotalPerPage] = useState<number>(4);

  const [reportData, setReportData] = useState<any>([]);
  const [reportId, setReportId] = useState<string>("");
  const [inputValue, setInputValue] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const [inboxList, setInboxList] = useState<InboxItem[]>([]);
  const [inboxOnDetail, setInboxOnDetail] = useState<InboxDetail | null>(null);
  const [searchValue, setSearchValue] = useState("");
  const [initialSelectionDone, setInitialSelectionDone] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const getInboxDataByUuid = async (uuid: string) => {
    try {
      setLoading(true);
      const response = await getInboxByUuid(uuid);
      setInboxOnDetail(response);
      setLoading(false);
    } catch (error) {
      console.error("Erro ao buscar a inbox por UUID:", error);
    }
  };

  const getInboxByReportUuid = async (page: number) => {
    try {
      const response = (await getInboxListByReportUuid(
        page,
        totalPerPage,
        reportId,
        searchValue
      )) as InboxListResponse;
      setLoading(true);
      setTotalItems(response.total);
      if (response.total <= 0) {
        setFirstMessage(true);
      } else {
        setFirstMessage(false);
      }

      if (response && response.data) {
        setInboxList(response?.data);
      }
      setLoading(false);
    } catch (error) {
      console.error("Erro ao buscar a inbox:", error);
    }
  };

  const handleSelectInbox = (uuid) => {
    getInboxDataByUuid(uuid);
    handleMarkAsViewed(uuid);
    setIsWriteEmail(false);
    setIsReply(false);
  };

  const handleSelectFirstInbox = (uuid, inboxData) => {
    setInboxOnDetail(inboxData);
    setIsReply(false);
  };

  const handleAddToEmail = () => {
    setToEmails((prevEmails) =>
      Array.from(new Set([...prevEmails, inputValue]))
    );
    setInputValue("");
  };

  const handleAddCCEmail = () => {
    setCcEmails((prevEmails) =>
      Array.from(new Set([...prevEmails, inputValue]))
    );
    setInputValue("");
  };

  const getHtmlContent = () => {
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    return draftToHtml(rawContentState);
  };
  const sortedInboxList = [...inboxList].sort((a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);

    return dateB.getTime() - dateA.getTime();
  });

  const getReportData = async () => {
    try {
      setLoading(true);

      if (typeof code === "string") {
        const response = await getReportByCodeAdmin(code);

        setReportData(response);
        setReportId(response.uuid);

        await getInboxByReportUuid(actualPage);
      }
    } catch (error) {
      console.error("Error:", error);
      notification.error({
        placement: "bottomRight",
        message: "Error",
        description: "An error occurred during the operation.",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    const htmlContent = getHtmlContent();
    try {
      const inboxData: CreateInboxDto = {
        title: values.title,
        body: htmlContent,
        topic: values.topic,
        name: values.name,
        emails: toEmails || [],
        emailCc: ccEmails || [],
        viewed: values.viewed || false,
        reportUuid: reportId,
        admin_sent: true,
      };

      const response = await createInbox(inboxData);
      if (response) {
        notification.success({
          placement: "bottomRight",
          message: "Inbox sended successful!",
          duration: 3,
        });
        form.resetFields();
        window.location.reload();
      }
    } catch (error) {
      notification.error({
        message: "Failed. Please check your the information again.",
        placement: "bottomRight",
        duration: 3,
      });
    }
  };

  const handlePageChange = (newPage: number) => {
    setActualPage(newPage);
    getInboxByReportUuid(newPage);
  };

  const handleReply = async (values) => {
    const htmlContent = getHtmlContent();
    try {
      if (!inboxOnDetail) {
        console.error("inboxOnDetail is null");
        return;
      }
      const inboxData: CreateInboxDto = {
        title: inboxOnDetail.title,
        body: htmlContent,
        topic: inboxOnDetail.topic,
        name: inboxOnDetail.name,
        emails: inboxOnDetail.emails || [],
        emailCc: inboxOnDetail.emailCc || [],
        viewed: inboxOnDetail.viewed || false,
        reportUuid: reportId,
        admin_sent: true,
      };

      const response = await createInbox(inboxData);
      if (response) {
        notification.success({
          placement: "bottomRight",
          message: "Inbox replied with successful!",
          duration: 3,
        });
        form.resetFields();
        window.location.reload();
      }
    } catch (error) {
      notification.error({
        message: "Failed. Please check your the information again.",
        placement: "bottomRight",
        duration: 3,
      });
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setToEmails([]);
    setCcEmails([]);
    setIsWriteEmail(false);
    setLoading(false);
    setInputValue("");
    setIsReply(false);
    setEditorState(EditorState.createEmpty());
  };

  const handleMarkAsViewed = async (uuid: string) => {
    try {
      const updatedInboxData: UpdateInboxDto = {
        viewed: true,
      };
      const response = await updateInbox(
        uuid,
        updatedInboxData as CreateInboxDto
      );
    } catch (error) {
      console.error("Error marking inbox as viewed:", error);
    }
  };

  useEffect(() => {
    getReportData();
  }, [reportId, searchValue]);

  useEffect(() => {
    setComponentMounted(true);
  }, []);

  useEffect(() => {
    if (
      componentMounted &&
      sortedInboxList.length > 0 &&
      !initialSelectionDone
    ) {
      const ultimaMensagem = sortedInboxList[0];

      handleSelectFirstInbox(ultimaMensagem.uuid, ultimaMensagem);

      // Marcar a seleção inicial como concluída
      setInitialSelectionDone(true);
    }
  }, [componentMounted, initialSelectionDone, sortedInboxList]);

  useEffect(() => {}, [inboxOnDetail, firstMessage]);

  return (
    <>
      <LayoutBaseAdmin>
        <Spin spinning={loading} fullscreen />
        <div className="flex flex-col font-nunito w-full h-full">
          <div className="flex justify-between items-center">
            <TitleWithBreadCrumb
              title="Inbox"
              actualPage="Inbox"
              backPage="Report Detail"
              navigateTo={`/admin/report/details?code=${code}`}
            />
            <Button
              className="cursor-pointer font-bold text-base text-center bg-light_blue-400 text-white-A700"
              shape="round"
              size="large"
              color="light_blue_400"
              onClick={() => setIsModalOpen(true)}
            >
              Original Report
            </Button>
            {isModalOpen && (
              <ModalText
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                modalTitle="Original Report"
                content={reportData.reportText}
              />
            )}
          </div>

          <div className="flex flex-row items-start justify-start w-full flex-wrap py-8 px-6 md:px-0 flex-wrap">
            <div className="bg-white-A700 border border-blue_gray-50 border-solid flex flex-col gap-8 items-start justify-start px-8 py-6 w-full lg:w-1/3">
              <div className="flex items-center gap-2 whitespace-nowrap">
                <Text
                  className="text-blue_gray-900 text-sm tracking-[-0.28px] w-full"
                  size="txtPlusJakartaSansSemiBold14"
                >
                  Report code:
                </Text>
                <Text
                  className="text-blue_gray-900 text-sm tracking-[-0.28px]"
                  size="txtPlusJakartaSansRegular12"
                >
                  {reportData.code}
                </Text>
              </div>
              <Input
                size="large"
                placeholder="Search Here..."
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                className="w-full"
                allowClear
                prefix={
                  <Img
                    className="mt-px mb-0.5 h-4 mr-3"
                    src="/images/img_contrast.svg"
                    alt="contrast"
                  />
                }
              />
              <>
                <Text
                  className="text-blue_gray-900 text-sm tracking-[-0.28px] w-full"
                  size="txtPlusJakartaSansSemiBold14"
                >
                  Messages:
                </Text>
                {Array.isArray(inboxList) ? (
                  inboxList.map((inbox) => (
                    <div
                      key={inbox.uuid}
                      onClick={() => handleSelectInbox(inbox.uuid)}
                      style={{ cursor: "pointer" }}
                      className="flex flex-col gap-2 items-center justify-between px-5 py-6 rounded-[10px] w-full"
                    >
                      <div className="flex flex-row w-full justify-between">
                        <div className="flex flex-row gap-3 items-center justify-start w-5/6">
                          <Img
                            className="h-[29px] w-[29px]"
                            src="/images/img_settings_light_blue_400.svg"
                            alt="settings"
                          />

                          <div className="flex flex-col gap-2">
                            {inbox.admin_sent && (
                              <Text
                                className="text-blue_gray-900 text-sm tracking-[-0.28px] w-7"
                                size="txtPlusJakartaSansSemiBold14"
                                style={{ textOverflow: "ellipsis" }}
                              >
                                Loqi
                              </Text>
                            )}
                            {!inbox.admin_sent && (
                              <Text
                                className="text-blue_gray-900 text-sm tracking-[-0.28px] w-full"
                                size="txtPlusJakartaSansSemiBold14"
                                style={{ textOverflow: "ellipsis" }}
                              >
                                {inbox.name ? inbox.name : "Whistleblower"}
                              </Text>
                            )}

                            <Text
                              className="text-gray-900_01 text-xs tracking-[-0.12px] w-full"
                              size="txtPlusJakartaSansRegular12"
                              style={{ textOverflow: "ellipsis" }}
                            >
                              {inbox.title}
                            </Text>
                          </div>
                        </div>

                        <div className="flex flex-col gap-2 items-end w-1/6">
                          <Text
                            className="text-blue_gray-400 text-xs  text-right w-full"
                            size="txtNunitoRegular12Bluegray400"
                          >
                            {formatDate(inbox.createdAt)}
                          </Text>
                          <Text className="text-blue_gray-400 text-xs text-right w-full">
                            {formatTime(inbox.createdAt)}
                          </Text>
                        </div>
                      </div>
                      <div className="w-full">
                        <span
                          className="limited-lines"
                          dangerouslySetInnerHTML={{
                            __html: inbox.body,
                          }}
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No data.</p>
                )}

                {inboxList.length ? (
                  <div style={{ marginLeft: "auto" }}>
                    <Pagination
                      current={actualPage}
                      total={totalItems}
                      onChange={handlePageChange}
                      pageSize={totalPerPage}
                    />
                  </div>
                ) : (
                  <>No messages</>
                )}

                <Button
                  className="cursor-pointer font-bold text-base text-center w-full bg-light_blue-400 text-white-A700"
                  shape="round"
                  size="large"
                  color="light_blue_400"
                  onClick={() => setIsWriteEmail(true)}
                >
                  Create new message
                </Button>
              </>
            </div>

            {!isWriteEmail ? (
              <div className="flex flex-col gap-8 items-center justify-start w-full lg:w-2/3 border border-blue_gray-50 border-solid p-6">
                {/* First Information Message */}
                {inboxOnDetail && (
                  <>
                    <div className="border items-center p-4 border-blue_gray-50 border-solid flex lg:flex-row flex-col items-center justify-between w-full ">
                      <div className="flex flex-row items-center gap-3">
                        <Img
                          className="h-8"
                          src="/images/img_logo.svg"
                          alt="logo"
                        />
                        <Text
                          className="text-blue_gray-900 text-lg"
                          size="txtNunitoRegular18Bluegray900"
                        >
                          {inboxOnDetail.name
                            ? inboxOnDetail.name
                            : !inboxOnDetail.admin_sent
                            ? "Whistleblower"
                            : "Loqi"}
                        </Text>
                      </div>

                      <Text
                        className="text-blue_gray-400 text-sm"
                        size="txtNunitoRegular14"
                      >
                        {formatFormalDate(inboxOnDetail.createdAt)}
                      </Text>
                    </div>

                    {/* Message Detail */}
                    <div className="flex flex-col lg:gap-10 items-start justify-start w-full">
                      <div className="flex flex-col items-start justify-start w-full gap-7">
                        <div className="w-full">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: inboxOnDetail.body,
                            }}
                          />
                        </div>
                        {/* Reply */}
                        {isReply && inboxOnDetail && (
                          <div className="flex flex-col gap-8 items-center justify-start w-full border border-blue_gray-50 border-solid ">
                            <div className="p-4 items-center flex lg:flex-row flex-col items-center justify-between w-full ">
                              <div className="flex flex-row items-center gap-3">
                                <Img
                                  className="h-8"
                                  src="/images/img_logo.svg"
                                  alt="logo"
                                />
                                <Text
                                  className="text-blue_gray-900 text-lg"
                                  size="txtNunitoRegular18Bluegray900"
                                >
                                  To:{" "}
                                  {reportData.email
                                    ? reportData.email
                                    : "Whistleblower"}
                                </Text>
                              </div>

                              <Text
                                className="text-blue_gray-400 text-sm"
                                size="txtNunitoRegular14"
                              >
                                {dayjs().format("D MMMM YYYY : h:mmA")}
                              </Text>
                            </div>

                            <div className=" flex flex-col gap-4 items-start justify-start px-4 py-2 w-full">
                              <div className="flex flex-row items-center gap-3">
                                <div>
                                  <span className="text-blue_gray-400 font-nunito text-left font-normal">
                                    Title:{" "}
                                  </span>
                                  <span className="text-blue_gray-900 font-nunito text-left font-normal">
                                    {inboxOnDetail.title}
                                  </span>
                                  <br />
                                  <span className="text-blue_gray-400 font-nunito text-left font-normal">
                                    Topic:{" "}
                                  </span>
                                  <span className="text-blue_gray-900 font-nunito text-left font-normal">
                                    {inboxOnDetail.topic}
                                  </span>
                                  <br />
                                  <span className="text-blue_gray-400 font-nunito text-left font-normal">
                                    Name:{" "}
                                  </span>
                                  <span className="text-blue_gray-900 font-nunito text-left font-normal">
                                    {inboxOnDetail.name}
                                  </span>
                                </div>
                              </div>
                              <Form
                                form={form}
                                onFinish={handleReply}
                                className="flex flex-col gap-5 w-full"
                                layout="vertical"
                              >
                                {/* Body do Inbox */}
                                <div className="border-blue_gray-50 border-solid border flex flex-row gap-4 items-start justify-start px-4 py-2 w-full">
                                  <Form.Item
                                    label="Text"
                                    name="text"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Digite um valor válido",
                                      },
                                    ]}
                                  >
                                    <Editor
                                      wrapperClassName="w-full"
                                      editorClassName="w-full"
                                      editorState={editorState}
                                      onEditorStateChange={(newEditorState) =>
                                        setEditorState(newEditorState)
                                      }
                                      toolbar={{
                                        options: [
                                          "inline",
                                          "blockType",
                                          "fontSize",
                                          "fontFamily",
                                          "list",
                                          "textAlign",
                                          "colorPicker",
                                          "link",
                                          "embedded",
                                          "emoji",
                                          "image",
                                          "remove",
                                          "history",
                                        ],
                                        inline: { inDropdown: true },
                                        list: { inDropdown: true },
                                        textAlign: { inDropdown: true },
                                        link: { inDropdown: true },
                                        history: { inDropdown: true },
                                      }}
                                    />
                                  </Form.Item>
                                </div>

                                <div className="w-full flex items-center justify-end gap-3">
                                  <Button
                                    className="border text-blue_gray-400 border-solid cursor-pointer font-semibold min-w-[102px] rounded-[20px] text-center text-sm"
                                    onClick={handleCancel}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    className="border border-light_blue-400 bg-light_blue-400 text-white-A700 border-solid cursor-pointer font-semibold min-w-[102px] rounded-[20px] text-center text-sm"
                                    type="primary"
                                    htmlType="submit"
                                  >
                                    Reply
                                  </Button>
                                </div>
                              </Form>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {!isReply && !inboxOnDetail?.admin_sent && (
                  <>
                    <div className="w-full flex justify-end">
                      <Button
                        className="cursor-pointer flex items-center justify-center min-w-[155px]"
                        shape="round"
                        color="light_blue_400"
                        onClick={
                          firstMessage
                            ? () => setIsWriteEmail(true)
                            : () => setIsReply(true)
                        }
                      >
                        <div className="font-bold text-base text-left">
                          Reply
                        </div>
                      </Button>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div className="flex flex-col gap-8 items-center justify-start w-full lg:w-2/3 border border-blue_gray-50 border-solid p-6">
                <div className="border p-4 items-center border-blue_gray-50 border-solid flex lg:flex-row flex-col items-center justify-between w-full ">
                  <div className="flex flex-row items-center gap-3">
                    <Img
                      className="h-8"
                      src="/images/img_logo.svg"
                      alt="logo"
                    />
                    <Text
                      className="text-blue_gray-900 text-lg"
                      size="txtNunitoRegular18Bluegray900"
                    >
                      loqi_team@loqi.com
                    </Text>
                  </div>

                  <Text
                    className="text-blue_gray-400 text-sm"
                    size="txtNunitoRegular14"
                  >
                    {dayjs().format("D MMMM YYYY : h:mmA")}
                  </Text>
                </div>
                <Form
                  form={form}
                  onFinish={handleSubmit}
                  className="flex flex-col gap-5 w-full"
                  layout="vertical"
                >
                  {/* Title and Topics */}
                  <div className="border-blue_gray-50 border-solid border flex flex-col gap-4 items-start justify-start px-4 py-2 w-full">
                    {/* First Line - Topic and Title */}
                    <div className="flex flex-row gap-4 w-full">
                      {/* Topic */}
                      <Form.Item
                        className="flex-1"
                        name="topic"
                        label={
                          <Text
                            className="text-blue_gray-400 text-sm w-auto"
                            size="txtNunitoRegular14"
                          >
                            Topic
                          </Text>
                        }
                      >
                        <Input
                          id="topic"
                          type="text"
                          size="large"
                          placeholder="Type here your Topic"
                          className="w-full"
                          allowClear
                        />
                      </Form.Item>
                      {/* Title */}
                      <Form.Item
                        className="flex-1"
                        name="title"
                        label={
                          <Text
                            className="text-blue_gray-400 text-sm w-auto"
                            size="txtNunitoRegular14"
                          >
                            Title
                          </Text>
                        }
                      >
                        <Input
                          id="title"
                          type="text"
                          size="large"
                          placeholder="Type here your Title"
                          className="w-full"
                          allowClear
                        />
                      </Form.Item>
                    </div>

                    {/* Second Line - Name */}
                    <Form.Item
                      className="flex-1 w-1/2"
                      name="name"
                      label={
                        <Text
                          className="text-blue_gray-400 text-sm w-auto"
                          size="txtNunitoRegular14"
                        >
                          Name (Optional)
                        </Text>
                      }
                    >
                      <Input
                        id="name"
                        type="text"
                        size="large"
                        placeholder="Type here your Name"
                        className="w-full"
                        allowClear
                      />
                    </Form.Item>
                  </div>

                  {/* Body do Inbox */}
                  <div className="border-[#d9d9d9] rounded-md border-solid border flex flex-row gap-4 items-start justify-start px-4 py-2 w-full">
                    <Form.Item
                      label="Message"
                      name="text"
                      rules={[
                        {
                          required: true,
                          message: "Digite um valor válido",
                        },
                      ]}
                    >
                      <Editor
                        wrapperClassName="w-full"
                        editorClassName="w-full"
                        editorState={editorState}
                        onEditorStateChange={(newEditorState) =>
                          setEditorState(newEditorState)
                        }
                        className="border-[#d9d9d9] border-solid border"
                        toolbar={{
                          options: [
                            "inline",
                            "blockType",
                            "fontSize",
                            "fontFamily",
                            "list",
                            "textAlign",
                            "colorPicker",
                            "link",
                            "embedded",
                            "emoji",
                            "image",
                            "remove",
                            "history",
                          ],
                          inline: { inDropdown: true },
                          list: { inDropdown: true },
                          textAlign: { inDropdown: true },
                          link: { inDropdown: true },
                          history: { inDropdown: true },
                        }}
                      />
                    </Form.Item>
                  </div>

                  <div className="w-full flex items-center justify-end gap-3">
                    <Button
                      className="border text-blue_gray-400 border-solid cursor-pointer font-semibold min-w-[102px] rounded-[20px] text-center text-sm"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="border border-light_blue-400 bg-light_blue-400 text-white-A700 border-solid cursor-pointer font-semibold min-w-[102px] rounded-[20px] text-center text-sm"
                      type="primary"
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              </div>
            )}
          </div>
        </div>
      </LayoutBaseAdmin>
    </>
  );
};

export default InboxAdmin;
