import { Breadcrumb, Button } from "antd";
import { Img } from "components/Img";
import { Text } from "components/Text";
import React from "react";
import { useNavigate } from "react-router-dom";

interface TitleWithBreadCrumbProps {
  backPage: string;
  actualPage: string;
  title: string;
  navigateTo: string;
}

const TitleWithBreadCrumb = ({
  backPage,
  actualPage,
  title,
  navigateTo,
}: TitleWithBreadCrumbProps) => {
  const navigation = useNavigate();

  return (
    <div className="flex lg:1/2 w-full flex-col gap-3 p-4 md:p-0 font-nunito flex-wrap">
      <Breadcrumb
        items={[
          {
            title: <a href={navigateTo}>{backPage}</a>,
          },
          {
            title: actualPage,
          },
        ]}
      />

      <div className="flex items-center gap-2 font-nunito">
        <Button
          shape="circle"
          size="large"
          icon={<Img src="/images/undo.png" />}
          onClick={() => navigation(navigateTo)}
        />
        <Text
          className="md:text-3xl lg:text-[28px] break-all text-[24px] text-blue_gray-900"
          size="txtNunitoBold32"
        >
          {title}
        </Text>
      </div>
    </div>
  );
};

export default TitleWithBreadCrumb;
