import axios from "axios";
import { parseCookies } from "nookies";

const { "auth.token": token } = parseCookies();
const { "auth.report.token": tokenWhistleBlower } = parseCookies();
const baseUrl = process.env.REACT_APP_BASE_URL;

export const api = axios.create({
  baseURL: baseUrl,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const apiWhistleBlower = axios.create({
  baseURL: baseUrl,
  headers: {
    Authorization: `Bearer ${tokenWhistleBlower}`,
  },
});

const HandleError = (error: any) => {
  "Falha na requisição";
};

export async function apiRequestWhistleBlower<T>(
  method: "get" | "post" | "put" | "delete",
  endpoint: string,
  data?: any
): Promise<T> {
  try {
    const response = await apiWhistleBlower[method](endpoint, data);

    if (response && response.data) {
      return response.data as T;
    } else {
      throw new Error("Falha na requisição");
    }
  } catch (error: any) {
    console.error(error);
    HandleError(error);
    throw error;
  }
}

export async function apiRequest<T>(
  method: "get" | "post" | "put" | "delete",
  endpoint: string,
  data?: any
): Promise<T> {
  try {
    const response = await api[method](endpoint, data);

    if (response && response.data) {
      return response.data as T;
    } else {
      throw new Error("Falha na requisição");
    }
  } catch (error) {
    console.error(error);
    HandleError(error);
    throw error;
  }
}

export const apiForm = axios.create({
  baseURL: baseUrl,
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data",
  },
});
