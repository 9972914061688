import axios from "axios";
import { apiRequest } from "./api";
import { parseCookies } from "nookies";
const baseUrl = process.env.REACT_APP_BASE_URL;
export async function createInbox(inboxData: CreateInboxDto) {
  try {
    const response = await apiRequest<any>("post", "/inbox/create", inboxData);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function createInboxFromGpt(inboxData: CreateInboxDto) {
  try {
    const response = await apiRequest<any>(
      "post",
      "/inbox/create-initial-inbox",
      inboxData
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getInboxByUuid(uuid: string) {
  try {
    const response = await apiRequest<any>(
      "get",
      `/inbox/find-by-uuid?uuid=${uuid}`
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export async function updateInbox(uuid: string, inboxData: CreateInboxDto) {
  try {
    const response = await apiRequest<any>(
      "put",
      `/inbox/update?uuid=${uuid}`,
      inboxData
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export async function selectInbox() {
  try {
    const response = await apiRequest<any>("get", `/inbox/select`);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getInboxListByReportUuid(
  page,
  itemsPerPage,
  reportUuid,
  search?
) {
  try {
    const response = await apiRequest("get", `/inbox/list-by-reportUuid`, {
      params: {
        page,
        itemsPerPage,
        reportUuid,
        search,
      },
    });

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getWhistleBlowerInboxByUuid(uuid: string) {
  if (uuid) {
    try {
      const { "auth.report.token": tokenWhistleBlower } = parseCookies();

      console.log(tokenWhistleBlower, "token");

      const response = await axios.get(
        `/inbox/find-by-uuid-whistleBlower?uuid=${uuid}`,
        {
          baseURL: baseUrl,
          headers: {
            Authorization: `Bearer ${tokenWhistleBlower}`,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      if (error.response.data.message.includes("Access")) {
      }
      throw error;
    }
  }
}

export async function getWhistleBlowerInboxListByReportUuid(
  page,
  itemsPerPage,
  reportUuid,
  search?
) {
  if (reportUuid) {
    try {
      const { "auth.report.token": tokenWhistleBlower } = parseCookies();

      const response = await axios.get(
        "/inbox/list-by-reportUuid-whistleBlower",
        {
          baseURL: baseUrl,
          headers: {
            Authorization: `Bearer ${tokenWhistleBlower}`,
          },
          params: {
            page,
            itemsPerPage,
            reportUuid,
            search,
          },
        }
      );
      return response.data as InboxListResponse;
    } catch (error: any) {
      if (error.response.data.message.includes("Access")) {
        window.location.href = "/whistleBlower";
      }
      throw error;
    }
  }
}
