import { apiRequest } from "./api";


export async function getUsersByType(type:string) {
  const response = await apiRequest<any>('get', `/users/find-by-type?type=${type}`);
  return response;
}

export async function getUsersByUuid(uuid:string) {
  const response = await apiRequest<any>('get', `/users/find-by-uuid?uuid=${uuid}`);
  return response;
}

export async function putUserByUuid(uuid:string, userData: UpdateUsersDto) {
  const response = await apiRequest<any>('put', `/users/update?uuid=${uuid}`, userData);
  return response;
}