import React, { createContext, ReactNode, useEffect, useState } from "react";

import { parseCookies, destroyCookie, setCookie } from "nookies";

import { login, verifyToken } from "../services/auth";

type AuthProviderProps = {
  children: ReactNode;
};

type AuthContextData = {
  signInByEmail: (email: string, password: string) => Promise<SucessAuthDto>;
  signOutData: () => Promise<void>;
  user: ReadUsersDto | undefined;
  isAuthenticated: boolean;
  controllerAtt: boolean;
  setControllerAtt: (value: boolean) => void;
};

export const AuthContext = createContext({} as AuthContextData);

let authChannel: BroadcastChannel;

export async function signOutData() {
  destroyCookie(undefined, "auth.token", {
    path: "/",
  });
}

export function AuthAdminProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<ReadUsersDto>();
  const [controllerAtt, setControllerAtt] = useState(false);
  const isAuthenticated = !!user;

  const getUserInfos = async () => {
    const { "auth.token": token } = parseCookies();

    if (token) {
      try {
        const response: ReadUsersDto | undefined = await verifyToken();
        if (response) {
          const { uuid, name, email, createdAt, updatedAt, type } = response;

          setUser({ uuid, name, email, createdAt, updatedAt, type });
        }
      } catch (error) {
        signOutData();
      }
    }
  };

  useEffect(() => {
    authChannel = new BroadcastChannel("auth");

    authChannel.onmessage = (message) => {
      switch (message?.data) {
        case "signOut":
          signOutData();
          break;
        default:
          break;
      }
    };
  }, []);

  useEffect(() => {
    getUserInfos();
  }, []);

  interface AuthResult {
    sucess: boolean;
    user?: ReadUsersDto;
    errorMessage?: string;
  }

  async function signInByEmail(
    email: string,
    password: string
  ): Promise<AuthResult> {
    try {
      const response: VerifyAuthDto | undefined = await login({
        email: email,
        password: password,
      });

      if (!response) {
        return { sucess: false, errorMessage: "Authentication failed." };
      }

      const { accessToken, userData } = response;

      setCookie(undefined, "auth.token", accessToken, {
        maxAge: 60 * 60 * 24 * 30,
        path: "/",
      });

      if (userData) {
        return { sucess: true, user: userData };
      }

      return { sucess: false, errorMessage: "User data not available." };
    } catch (err) {
      return { sucess: false, errorMessage: "Error during authentication." };
    }
  }

  return (
    <AuthContext.Provider
      value={{
        signInByEmail,
        signOutData,
        isAuthenticated,
        user,
        controllerAtt,
        setControllerAtt,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
