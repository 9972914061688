import React from "react";

import { Button, Modal } from "antd";
import { Text } from "components/Text";

interface TermsAndConditionsModalProps {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  modalTitle: string;
  content: string;
  richText?: React.ReactNode;
}

const ModalText = ({
  isModalOpen,
  setIsModalOpen,
  modalTitle,
  richText,
  content,
}: TermsAndConditionsModalProps) => {
  return (
    <Modal
      open={isModalOpen}
      title={modalTitle}
      onOk={() => setIsModalOpen(false)}
      footer={null}
      onCancel={() => setIsModalOpen(false)}
    >
      <div>
        {content && (
          <Text
            className="leading-[24.00px] text-base text-blue_gray-900 max-h-[400px] overflow-auto my-10 px-5"
            size="txtNunitoRegular16"
          >
            {content}
          </Text>
        )}
        {richText}
      </div>
    </Modal>
  );
};

export default ModalText;
