import React, { useContext, useEffect, useState } from "react";

import TitleWithBreadCrumb from "components/TitleWithBreadCrumb";
import LayoutBaseAdmin from "components/LayoutBaseAdmin";
import { Form, Input, Button, Row, Col, Upload, message } from "antd";

import { AuthContext } from "contexts/AuthContext";
import { getUsersByUuid, putUserByUuid } from "services/userService";
import { Img } from "components";
import { Text } from "components/Text";
import { UploadOutlined } from "@ant-design/icons";
import { postFileProfile } from "services/storageService";

interface UserData {
  uuid: string;
  name: string;
  email: string;
  type: string;
  profileImageUrl: string;
}

const Profile: React.FC = () => {
  const [userInfo, setUserInfo] = useState<UserData | null>(null);
  const [imageUploaded, setImageUploaded] = useState(false);
  const [profileImage, setProfileImage] = useState<ReadStorageDto | null>(null);
  const { user } = useContext(AuthContext);
  const [uploadForm] = Form.useForm();
  const [editForm] = Form.useForm();

  const normFile = (e: any) => {
    setImageUploaded(true);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onFinishUpload = async (values) => {
    try {
      if (!userInfo) {
        throw new Error("UserInfo are required");
      }
      const response = await postFileProfile(
        values.upload[0].originFileObj,
        userInfo.uuid
      );
      if (response) {
        setProfileImage(response);
        uploadForm.resetFields();
        setImageUploaded(false);
        message.success("File uploaded with success");
        window.location.reload();
      } else {
        message.error("Fail");
      }
    } catch (error) {
      console.error("Fail:", error);
      message.error("Fail");
    }
  };
  const handleCancelUpload = () => {
    uploadForm.resetFields();
  };

  const onFinishEdit = async (values) => {
    try {
      const name = values.name;
      const email = values.email;

      const userData = {
        name,
        email,
      };
      if (!userInfo) {
        throw new Error("UserInfo are required");
      }

      const response = await putUserByUuid(userInfo.uuid, userData);
      if (response) {
        uploadForm.resetFields();
        message.success("Information updated with success");
        window.location.reload();
      } else {
        message.error("Fail");
      }
    } catch (error) {
      console.error("Fail:", error);
      message.error("Fail");
    }
  };

  const getUsersData = async () => {
    try {
      if (user && user.uuid) {
        const response = await getUsersByUuid(user.uuid);
        setUserInfo(response);
      }
    } catch (error) {
      console.error("Error fetching users data:", error);
    }
  };

  useEffect(() => {
    getUsersData();
  }, [user]);

  useEffect(() => {
    if (profileImage) {
      getUsersData();
    }
  }, [profileImage]);

  return (
    <>
      <LayoutBaseAdmin>
        {userInfo ? (
          <div className="flex flex-col font-nunito w-full h-full">
            <TitleWithBreadCrumb
              actualPage="Profile"
              title="Profile"
              backPage="Dashboard"
              navigateTo="/admin/dashboard"
            />

            <div className="flex flex-col md:flex-row gap-3 items-start justify-start w-full py-8 px-6 md:px-0">
              <div className="bg-white-A700 border border-blue_gray-50 border-solid flex flex-col gap-8 items-start justify-start px-8 py-6 w-1/3">
                <div className="flex flex-col items-center justify-center w-full">
                  <Img
                    src={userInfo?.profileImageUrl}
                    className="h-[250px] w-[250px] object-cover rounded-[16px]"
                    style={{ borderRadius: "50%" }}
                    alt="Profile Image"
                  />
                  <Form
                    form={uploadForm}
                    layout="vertical"
                    onFinish={onFinishUpload}
                  >
                    <Form.Item
                      name="upload"
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                      style={{ marginTop: "20px" }}
                    >
                      <Upload
                        name="logo"
                        action="/upload.do"
                        listType="picture"
                      >
                        <Button icon={<UploadOutlined />}>
                          Upload Profile Image
                        </Button>
                      </Upload>
                    </Form.Item>
                    {imageUploaded && (
                      <Form.Item>
                        <div className="flex gap-2 justify-center">
                          <Button type="default" htmlType="submit">
                            Upload
                          </Button>
                          <Button
                            type="default"
                            htmlType="button"
                            onClick={handleCancelUpload}
                          >
                            Cancel
                          </Button>
                        </div>
                      </Form.Item>
                    )}
                  </Form>

                  <div className="flex flex-col gap-[7px] items-start justify-start mt-[31px] w-full">
                    <Text
                      className="text-base text-blue_gray-400"
                      size="txtNunitoMedium16Bluegray400"
                    >
                      Name
                    </Text>
                    <Input
                      disabled
                      id="name"
                      size="large"
                      placeholder="Task name"
                      className="w-full"
                      value={userInfo?.name || ""}
                    />
                  </div>
                  <div className="flex flex-col gap-[5px] items-start justify-start mt-[25px] w-full">
                    <Text
                      className="text-base text-blue_gray-400"
                      size="txtNunitoMedium16Bluegray400"
                    >
                      Type:
                    </Text>

                    <Input
                      disabled
                      id="name"
                      size="large"
                      placeholder="Task name"
                      className="w-full"
                      value={userInfo?.type || ""}
                    />
                  </div>

                  <div className="flex flex-col gap-[5px] items-start justify-start mt-[25px] w-full">
                    <Text
                      className="text-base text-blue_gray-400"
                      size="txtNunitoMedium16Bluegray400"
                    >
                      E-mail:
                    </Text>

                    <Input
                      disabled
                      id="name"
                      size="large"
                      placeholder="Task name"
                      className="w-full"
                      value={userInfo?.email || ""}
                    />
                  </div>
                </div>
              </div>

              <div className="bg-white-A700 border border-blue_gray-50 border-solid flex flex-col gap-8 items-start justify-start px-8 py-6 w-2/3">
                <div className="flex flex-col items-center justify-start w-full">
                  <div className="bg-gray-50 flex flex-row md:gap-10 items-center justify-between p-4 rounded-tl rounded-tr w-full">
                    <Text className="ml-4 text-black-900 text-lg">
                      Edit Information
                    </Text>
                    <div className="bg-white-A700_3d flex flex-col items-center justify-center mr-4 p-1 rounded-lg w-8">
                      <Img
                        className="h-6"
                        src="/images/edit-2.svg"
                        alt="edit"
                      />
                    </div>
                  </div>

                  <div className="p-[31px] sm:px-5 w-full">
                    <Form
                      form={editForm}
                      layout="vertical"
                      onFinish={onFinishEdit}
                    >
                      <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12} md={8} lg={6}>
                          <Text
                            className="text-base text-blue_gray-400"
                            size="txtNunitoMedium16Bluegray400"
                          >
                            Name:
                          </Text>
                          <Form.Item name="name">
                            <Input size="large" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6}>
                          <Text
                            className="text-base text-blue_gray-400"
                            size="txtNunitoMedium16Bluegray400"
                          >
                            E-mail:
                          </Text>
                          <Form.Item name="email">
                            <Input size="large" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6}>
                          {/* <Text
                            className="text-base text-blue_gray-400"
                            size="txtNunitoMedium16Bluegray400"
                          >
                            Password:
                          </Text>
                          <Form.Item
                            name="password"
                            rules={[
                              {
                                required: true,
                                message: "Please input your password.",
                              },
                            ]}
                          >
                            <Input.Password size="large" />
                          </Form.Item> */}
                        </Col>
                      </Row>
                      <Form.Item>
                        <div className="flex flex-col md:flex-row gap-2 items-center justify-end w-auto sm:w-full">
                          <Button
                            type="default"
                            className=" border-black-50 border-solid flex items-center justify-center text-black-A700"
                            shape="round"
                            size="large"
                          >
                            Cancel
                          </Button>
                          <Button
                            type="primary"
                            htmlType="submit"
                            className="bg-light_blue-400 flex items-center justify-center text-white-A700 border border-none"
                            shape="round"
                            size="large"
                          >
                            Save
                          </Button>
                        </div>
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>Loading...</div>
        )}
      </LayoutBaseAdmin>
    </>
  );
};

export default Profile;
