import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotFound from "pages/NotFound";
import OpenInvestigationPage from "pages/admin/reports/OpenInvestigation";
import InboxAdmin from "pages/admin/inbox";
import Profile from "pages/admin/Profile";

// Admin Autentication pages
const RecoverPasswordAdmin = React.lazy(() => import("pages/admin/autentications/RecoverPassword"));
const LoginAdmin = React.lazy(() => import("pages/admin/autentications/Login"));
const ResetPasswordAdmin = React.lazy(() => import("pages/admin/autentications/ResetPassword"));
const RegisterStaffPage = React.lazy(() => import("pages/admin/autentications/Register"));


// Admin Pages 
const ReportsListAdmin = React.lazy(() => import("pages/admin/reports/ReportsList"));
const ReportDetailsAdmin = React.lazy(() => import("pages/admin/reports/ReportDetails/index"));
const ReportIssueDetailsAdmin = React.lazy(() => import("pages/admin/reports/ReportIssueDetails"));
const TaskDetailsAdmin = React.lazy(() => import("pages/admin/reports/TaskDetails"));
const ClosureformAdmin = React.lazy(() => import("pages/admin/reports/Closureform"));
const IntegrationsAdmin = React.lazy(() => import("pages/admin/Integrations"));
const Settings = React.lazy(() => import("pages/admin/Integrations/ChatGpt/Settings"));

const DashboardAdmin = React.lazy(() => import("pages/admin/Dashboard"));

// Whistleblower Autentication pages
const RecoverPasswordWhistleBlower = React.lazy(() => import("pages/whistleblower/autentications/RecoverPassword"));
const LoginWhistleBlower = React.lazy(() => import("pages/whistleblower/autentications/Login"));
const ResetPasswordWhistleBlower = React.lazy(() => import("pages/whistleblower/autentications/ResetPassword"));
const FirstAccessWhistleBlower = React.lazy(() => import("pages/whistleblower/autentications/FirstAccess"));
const AnonymousReportWhistleBlower = React.lazy(() => import("pages/whistleblower/autentications/AnonymousReport"));
const RegisterWhistleBlower = React.lazy(() => import("pages/whistleblower/autentications/Register"));

// Whistleblower pages
const ReportsInProgressWhistleBlower = React.lazy(() => import("pages/whistleblower/reports/ReportsList"));
const ReportStatusWhistleBlower = React.lazy(() => import("pages/whistleblower/reports/ReportStatus/index"));
const CreateReportWhistleBlower = React.lazy(() => import("pages/whistleblower/reports/CreateReport"));
const InboxWhistleBlower = React.lazy(() => import("pages/whistleblower/inbox"));

const ProjectRoutes = () => {
  return (
    <React.Suspense fallback={<>Loading...</>}>
      <Router>
        <Routes>

          {/* Default pages */}
          <Route path="/" element={<LoginAdmin />} />
          <Route path="*" element={<NotFound />} />

          {/* Admin Autentication*/}
          <Route path="/admin/recoverpassword" element={<RecoverPasswordAdmin />} />
          <Route path="/admin/login" element={<LoginAdmin />} />
          <Route path="/admin/register" element={<RegisterStaffPage />} />

          <Route path="/reset-password" element={<ResetPasswordAdmin />} />

          {/* Admin pages */}
          <Route path="/admin/reports" element={<ReportsListAdmin />} />
          <Route path="/admin/report/details" element={<ReportDetailsAdmin />} />
          <Route path="/admin/report/details/inbox" element={<InboxAdmin />} />
          <Route path="/admin/reports/details/openinvestigation" element={<OpenInvestigationPage />} />
          <Route path="/admin/report/issue" element={<ReportIssueDetailsAdmin />} />
          <Route path="/admin/report/task/details" element={<TaskDetailsAdmin />} />
          <Route path="/admin/report/closureform" element={<ClosureformAdmin />} />
          <Route path="/admin/integrations" element={<IntegrationsAdmin />} />
          <Route path="/admin/integrations/chatgpt-settings" element={<Settings />} />

          <Route path="/admin/dashboard" element={<DashboardAdmin />} />
          <Route path="/admin/profile" element={<Profile />} />



          {/* Whistleblower Autentication*/}
          <Route path="/whistleblower/recoverpassword" element={<RecoverPasswordWhistleBlower />} />
          <Route path="/whistleblower/login" element={<LoginWhistleBlower />} />
          <Route path="/whistleblower/resetpassword" element={<ResetPasswordWhistleBlower />} />
          <Route path="/whistleblower" element={<FirstAccessWhistleBlower />} />
          <Route path="/whistleblower/anonymousreport" element={<AnonymousReportWhistleBlower />} />
          <Route path="/whistleblower/register" element={<RegisterWhistleBlower />} />

          {/* Whistleblower pages*/}
          <Route path="/whistleblower/reports" element={<ReportsInProgressWhistleBlower />} />
          <Route path="/whistleblower/reportstatus" element={<ReportStatusWhistleBlower />} />
          <Route path="/whistleblower/createreport" element={<CreateReportWhistleBlower />} />
          <Route path="/whistleblower/inbox" element={<InboxWhistleBlower />} />

        </Routes>
      </Router>
    </React.Suspense>
  );
};
export default ProjectRoutes;
